<template>
  <b-card-code >
    <div class="d-flex justify-content-between">
      <h4>Edit User</h4>
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <!-- form -->
    <validation-observer ref="UserEditForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="editUser">
        <!-- Email -->
        <b-form-group label="Email" label-for="email">
          <template #label>
            <span>Email</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="email|required"
          >
            <b-form-input
              id="email"
              v-model="email"
              :state="errors.length > 0 ? false : null"
              name="email"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- new password with complecity -->

        <b-form-group label="Password" label-for="Password">
          <template #label>
            <span>Password</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="password"
              v-model="password"
              @input="password_check"
              :type="passwordFieldType"
              name="password"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <div class="row mt-1">
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': password.length > 9 }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="password.length ? 'fa-check' : 'fa-times'"
                />
                At least 10 characters
              </p>
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_uppercase }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_uppercase ? 'fa-check' : 'fa-times'"
                />
                Has a capital letter
              </p>
            </div>
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_lowercase }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_lowercase ? 'fa-check' : 'fa-times'"
                />
                Has a lowercase letter
              </p>
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_number }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_number ? 'fa-check' : 'fa-times'"
                />
                Has a number
              </p>
            </div>
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_special }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_special ? 'fa-check' : 'fa-times'"
                />
                Has a special character
              </p>
            </div>
          </div>
        </b-form-group>
        <!-- First Name -->
        <b-form-group label="First Name" label-for="first Name">
          <template #label>
            <span>First Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="first_name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="first_name"
              v-model="first_name"
              :state="errors.length > 0 ? false : null"
              name="first_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Last Name -->
        <b-form-group label="Last Name" label-for="Last Name">
          <template #label>
            <span>Last Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="last_name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="last_name"
              v-model="last_name"
              :state="errors.length > 0 ? false : null"
              name="last_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- <b-form-group v-if="is_staff_login" label="" label-for="Is staff">
          <b-form-checkbox
            id="checkbox-1"
            v-model="is_staff"
            name="is_staff"
            value="true"
            unchecked-value="false"
          >
            Is Staff (System User)?
          </b-form-checkbox>
        </b-form-group> -->

        <!-- Organization-->
        <!-- <b-form-group
          label="Organization"
          label-for="Organization"
          v-if="(is_staff === false || is_staff === 'false') && is_staff_login"
        >
          <b-form-select
            v-model="organization"
            :options="organizations"
          ></b-form-select>
          
        </b-form-group> -->

        <!-- Roles-->
        <b-form-group
          label="Role"
          label-for="Role"
          v-if="is_staff === false || is_staff === 'false'"
        >
          <template #label>
            <span>Role</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="role"
            :rules="{ required: true }"
          >
            <b-form-select v-model="role" :options="org_roles"></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Role" label-for="Role" v-else>
          <template #label>
            <span>Role</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="role"
            :rules="{ required: true }"
          >
            <b-form-select
              v-model="role"
              :options="system_roles"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="
            invalid ||
            (password !== '' && !has_number) ||
            (password !== '' && !has_lowercase) ||
            (password !== '' && !has_uppercase) ||
            (password !== '' && !has_special) ||
            (password !== '' && password.length < 10)
          "
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Update</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {
      is_staff_login: this.$store.state.app.user.is_staff,
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      is_staff: null,
      organization: null,
      org_id: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      role: "",
      organizations: [{ value: null, text: "--Select Organization--" }],
      org_roles: [{ value: null, text: "--Select Role--" }],
      system_roles: [{ value: null, text: "--Select Role--" }],
      isloading: false,
    };
  },
  created: function () {
    this.load();
  },
  watch: {
    is_staff: function (newVal, oldVal) {
      if (oldVal != null) {
        this.organization = null;
        this.role = null;
      }
    },
  },
  methods: {
    password_check: function () {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
    },
    load: function () {
      if (!this.is_staff_login) this.is_staff = false;
      // Load oragnization drop down
      var self = this;
      if (this.is_staff_login) {
        // this.organizations = this.$store.state.app.organizations;
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org/",
        };
        this.$http(o_options).then((res) => {
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };

            self.organizations.push(org);
          });
        });
      }

      // Load role dropdown
      const r_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/groups/",
      };
      this.$http(r_options).then((res) => {
        res.data.map(function (value, key) {
          let role = {
            value: res.data[key],
            text: res.data[key].name,
          };
          // console.log(res.data[key].profile.organization)
          if (res.data[key].role_type == 0) self.system_roles.push(role);
          if (res.data[key].role_type == 1) self.org_roles.push(role);
        });
        //  console.log("roles",self.roles)
      });

      // Load user details

      let url =
        process.env.VUE_APP_BASEURL + "user/users/" + this.$route.params.id;
      if (this.$route.query.is_staff) {
        url = url + "?role_type=0";
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data, "fdsajjdfksk");
        self.email = res.data.email;
        self.first_name = res.data.first_name;
        self.last_name = res.data.last_name;
        self.is_staff = res.data.is_staff;
        // if (res.data.profile != null)
        //   self.organization = res.data.profile.o_profile[0].org_id;
        // self.role = res.data.groups[0];
        self.role = res.data.current_groups[0];
        // console.log("applied role",self.role)
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    editUser: function () {
      this.$refs.UserEditForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          //call to axios
          let data = {
            // is_active: true,
            first_name: this.first_name,
            last_name: this.last_name,
            // is_staff: this.is_staff,
          };
          if (this.password) {
            data["password"] = this.password;
          }
          // if (!this.is_staff_login)
          //   this.organization = this.$store.state.app.organizationId;
          // if (
          //   (this.is_staff === false || this.is_staff === "false") &&
          //   this.organization
          // ) {
          //   data["profile"] = {
          //     organization: this.organization,
          //     is_organization_owner: false,
          //   };
          // }

          if (this.role) {
            let groups_list = [];
            groups_list.push(this.role);
            data["groups"] = groups_list;
          }
          let url =
            process.env.VUE_APP_BASEURL +
            "user/users/" +
            this.$route.params.id +
            "/";
          if (this.$route.query.is_staff) {
            url = url + "?role_type=0";
          }
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url: url,
          };
          var self = this;
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Updated User Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.push({ name: "Users" });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
            // if (res.data.id) {
            //   this.$router.push({ name: "Users" });
            // }
          });
          this.isloading = false;
        }
      });
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>
<style lang="scss">
.frmField {
  background-color: white;
  color: #495057;
  line-height: 1.25;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border: 0;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 90%;
}
.frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}
</style>